const messageTimeout = 5000;

function showMessageBox(type, message, extra, onClose=() => {}) {
    var boxType = type;
    var content = '';
    var topMessage = ''
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    if (boxType == 'danger') {
        topMessage = "ERROR"
    } else {
        topMessage = "SUCCESS" 
    }
    var messageBox = `
        <div class="alert-container fade in relative">
            <div class="alert-${boxType}">
                <img class="close-error close-sample" src="/skins/current-skin/images/icons/close-black.svg"/>
                <img class="alert-image" src="/skins/current-skin/images/icons/${boxType}.svg"/>
                <div class="alert">${message + content}!</div>
            </div>
        </div>
    `;
    
    $('.alert-box').html(messageBox).show();
    $('body').addClass('block-scroll');

    $('.alert-box .close-error').on('click', closeMessageBox);
    setTimeout(closeMessageBox, messageTimeout);

    function closeMessageBox() {
        $('.alert-box').html('').hide();
        $('body').removeClass('block-scroll');
        onClose();
    }
}

function showQuestionBox(message, extra, onConfirm, onClose=()=>{}) {
    var content = '';
    if (extra) {
        content = `<div>${extra.content}</div>`;
    }

    var messageBox = `
        <div class="alert-container question fade in relative">
            <div class="alert-danger">
                <img class="close-error close-sample" src="/skins/current-skin/images/icons/close-black.svg"></img>
                <img class="alert-image" src="/skins/current-skin/images/icons/danger.svg"></img>
                <div class="alert">${message + content}</div>
                <div class="flex mt-16">
                    <input type="button" class="btn btn-secondary width-100 mr-8 close-button" value="${buttons_close}">
                    <input type="button" class="btn btn-primary width-100 ml-8 confirm-button" value="${buttons_confirm}">
                </div>
            </div>
        </div>
    `;
    
    $('.alert-box').html(messageBox).show();
    $('body').addClass('block-scroll');

    $('.alert-box .confirm-button').on('click', onConfirm);
    $('.alert-box .close-error').on('click', closeMessageBox);
    setTimeout(closeMessageBox, messageTimeout);

    function closeMessageBox() {
        $('.alert-box').html('').hide();
        $('body').removeClass('block-scroll');
        onClose();
    }
}

$(document).ready(function() {
    
    var errorFound = getUrlParam("error") && (getUrlParam("errorCode") != 263);

    if (errorFound) {
        $('body').addClass('block-scroll')
        $('.alert-box').show();
        $('.close-sample').on('click', function () {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        });
        setTimeout(function() {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        }, messageTimeout);
    }

});