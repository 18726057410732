function loginPage() {
    if (window.location.search != '') {
        if (document.getElementById('shoptype') != undefined) {
            var type = GetUrlValue('business');
            document.getElementById('shoptype').value = 'type:' + type;
        }
    }

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });

    if (getUrlParam('token') != undefined) {
        $('#token').val(getUrlParam('token'))
        $('#token').attr('readonly', 'readonly')
    }
    if (getUrlParam('email') != undefined) {
        $('#password-new-form-email').addClass('hide');
        $('#password-new-form-token').removeClass('hide');
        $('#recoveremail').val(decodeURIComponent(getUrlParam('email')))
        $('#email').val(decodeURIComponent(getUrlParam('email')))
        $('#email').parents('.label-input').removeClass('hide')
    }

    // password recovery form
    $('#password-new-form-email').on('submit', function (event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    const email = $('#recoveremail').val();
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                    $('#password-new-form-email').addClass('hide');
                    $('#password-new-form-token').removeClass('hide')
                        .find('[name="email"]').val(email);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $form.find(':input').prop('disabled', false);
            }
        });

    });

    $('#password-new-form-token').validate({
        rules: {
            token: 'required',
            password: {
                required: true,
                complexPassword: true
            }
        },
        submitHandler: function (form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_MODIFIED']);
                        setTimeout(() => {
                            window.location.href = $('#password-new-form-token').attr('next-url')
                        }, 5000);
                    }
                    else if (data.errors.message == 'fault') {
                        showMessageBox('danger', msg['ERR_WRONG_TOKEN']);
                    }
                    else {
                        showMessageBox('danger', msg[data.errors.message] || msg['MSG_GENERIC_ERROR']);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    // subscribe form
    $.getScript('https://www.google.com/recaptcha/api.js');

    $('a[href*=login-page]').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $('html,body').animate({ scrollTop: targetOffset }, 1000);
                return false;
            }
        }
    });
}

function GetUrlValue(VarSearch) {
    var SearchString = window.location.search.substring(1);
    var VariableArray = SearchString.split('&');
    for (var i = 0; i < VariableArray.length; i++) {
        var KeyValuePair = VariableArray[i].split('=');
        if (KeyValuePair[0] == VarSearch) {
            return KeyValuePair[1];
        }
    }
}

var subscribeFormValidator = $('#subscribe-form').validate({
    rules: {
        cap: { zip: true }
    },
    errorPlacement: function () {},
    submitHandler: function(form) {
        const $form = $(form);
        submitSubscribeForm($form);
    }
});

function subscribeCaptcha() {
    const $form = $('#subscribe-form');

    if ($form.valid()) {
        submitSubscribeForm($form)
    }
}

function submitSubscribeForm($form) {
    if ($('#password1').val() != $('#password2').val()) {
        showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
    }
    else {
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    if (dataLayer) {
                        pushGTMEventWithCallback(500, {
                            event: 'sign_up',
                            eventCallback: function() {
                                window.location = $form.data('redirectsuccess');
                            }
                        })
                    }
                    else {
                        window.location = $form.data('redirectsuccess');
                    }
                } else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }
}

function subscribeCaptchaExpired() {
    const subscribeForm = $('#subscribe-form');
    subscribeForm.removeAttr('action');
    subscribeForm.find('button[type=submit]').prop('disabled', true);
}