function searchComponent() {
	if (document.getElementById('search-type') != null) {

		document.getElementById('search-archive').oninput = function() {
			document.getElementsByClassName('av-search-button')[0].setAttribute('href',document.getElementById('search-type').value+"?search="+document.getElementById('search-archive').value)
		};

		$("#search-archive").on('keyup', function (e) {
			if (e.keyCode == 13) {
				document.getElementsByClassName('av-search-button')[0].click();
			}
		});

	}

	function openSearch(){
		$('.av-search-wrapper')
		.addClass('av-show-search')
		.delay(100)
		.queue(function(next) {
			$(this).addClass('av-opened-search');
			next();

		})
		$('#search-archive').focus();
	};

	function closeSearch(){
		/*var pagePosition = $('body').css('top');
			pagePosition = pagePosition.replace('px', '');
			pagePosition = pagePosition.replace('-','');*/

		$('.av-search-wrapper')
		.removeClass('av-opened-search')
		.delay(500)
		.queue(function(next) {
			$(this).removeClass('av-show-search');
			next();
		})
		/*.queue(function(next) {
	        $('body').removeClass('av-noscroll-page');
	        $(document).scrollTop( pagePosition );
	        $('body').removeAttr('style');
			next();
		})*/
	};

	$('#search-submit').on('click', function() {
		$('#search-submit').closest('form').trigger('submit');
	});

	$('#search-submit').closest('form').on('submit', function(event) {
		event.preventDefault();

		const form = $(this);
		const search = form.find('[name="search"]').val();
		const url = `${form.attr('action')}?search=${search}&sort=id&dir=desc`;
		window.location.href = url;
	});
	
	$('.av-search:not(.av-close-search)').click(function(event) {
		openSearch();
		event.preventDefault();

	});
	
	$('.av-close-search').click(function(event) {
		closeSearch();
		event.preventDefault();

	});
}