function cartPage() {

    loadQtyInputs();

    $('.showOtherSize').click(function() {
        $(this).next('.otherSize').slideToggle()
    })
    
    $('.table-cell.-availability').each(function() {
        if ($(this).text() == '0') {
            $(this).css({color: 'red'});
        }
    });

    $('#close-modal-wishlist').on('click', function () {
        $('#shareWishlistPopup').foundation('close');
    });

    $('.btn-wishlist-remove').on('click', function(event) {
        var product = $(this).parents('.wishlist-product');
        event.preventDefault();
        var oldModel = product.find('input').val();
        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
            data: 'qty_model_' + oldModel + '=' + 0,
            success: function(data) {
                window.location.href = '/current/profile/wishlist';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.carrier-form').on('change', function() {
        const form = $(this);
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: {
                country_id: form.find('select[name=country_id]').val(),
                carrier_id: form.find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // update cart quantity
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const form = $(this);
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
	                const row = form.closest('.row');
                	rewixUpdateCart(row);
                    window.location.href = '/current/cart';
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        var data = {};
        const button = $(this);
        data[button.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: button.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
	                const row = button.closest('tr');
	                rewixUpdateCart(row, true);
                    showMessageBox('success', msg['MSG_REMOVEDFROMCART']);
                    setTimeout(function(){ window.location.href = '/current/cart'; }, 1000);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart item
    $('.btn-cart-remove-all').on('click', function(event) {
        event.preventDefault();
        const button = $(this);
        var currentTable = button.attr('data-product-id');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[button.attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: button.attr('data-action'),
            data: data,
            success: function(data) {
                const container = button.closest('#cart-container');
                rewixUpdateCart(container, true);
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // Coupon form
    $(".burn-coupon-form").on('submit', function(event) {
        event.preventDefault();
        const form = $(this);
        if (form.find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: form.attr('action'),
                data: form.serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });


    // calculate each product total
    function singleProductQty() {
        $('.cart-product').each(function () {
            var inputs = $(this).find('.i-number');
            var total = 0;

            inputs.each(function () {
                total += parseInt($(this).val());
            });

            $(this).find('.single-product-qty').text(total);
        });
    }


    // calculate total quantity
    function totalQty() {
        var total = 0;

        $('.single-product-qty').each(function () {
            total += parseInt($(this).text());
        });

        $('#total-qty').text(total);
    }

    // calculate total quantity
    function totalDiscount() {
        var total = 0;

        $('.discount-value').each(function () {
            var currentValue = parseFloat($(this).text()).toFixed(2);
            total = parseFloat(total) + parseFloat(currentValue);
        });

        $('#total-discount').text(parseFloat(total).toFixed(2) + "€");
    }


    singleProductQty();
    totalQty();
    totalDiscount();


    // remove all products
    $('.cart-remove-all-products').on('click', function(event) {
        showQuestionBox(msg['MSG_DELETEALLPRODUCTS'], null, removeAllProducts);
    });

}

function removeAllProducts() {

    $('div.alert-box').html('');
    $('.alert-box').hide()
    $('body').removeClass('block-scroll')
    var products = document.getElementsByClassName('cart-update').length;
    var i = 0;
    $('.cart-update').each(function() {
        event.preventDefault();
        const form = $(this);
        var currentTable = form.find('.cart-table');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[this.name] = 0;
        });

        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart',
            data: data,
            dataType: 'json',
            success: function(data) {
                rewixUpdateCart(form, true);
                i++;
                if (i == products) {
                    window.location.href = '/current/cart';
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
            }
        });
    });

}