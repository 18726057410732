function profilePage() {
    'use strict';
    var currentCountry = $( "#country" ).find('option:selected').data('eu');

    if (currentCountry == 'EU') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').attr('required','required');
    } else if (currentCountry == 'IT') {
        $('#cfpiva-div').removeClass('hide');
        $('#pec-sdi-div').removeClass('hide');
        $('#pec-div').removeClass('hide');
        $('#sdi-div').removeClass('hide');
        $('#cfpiva').attr('required','required');
    } else if (currentCountry != undefined) {
        $('#cfpiva-div').addClass('hide');
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
        $('#cfpiva').removeAttr('required');
    }

    $('#copyLink').click(function() {
        var ret = copyTextToClipboard($('#linkToCopy').val())
        if (ret) {
            $('#copyLink').html('Link copiato!')
        }
    })

    $('#copy-dispatch').on('click', function(event) {

        document.getElementById('addressee').value = document.getElementById('addressee_disp').value
        document.getElementById('cap').value = document.getElementById('cap_disp').value
        document.getElementById('city').value = document.getElementById('city_disp').value
        document.getElementById('number').value = document.getElementById('number_disp').value
        document.getElementById('prov').value = document.getElementById('prov_disp').value
        document.getElementById('street').value = document.getElementById('street_disp').value

        $('#country').val(document.getElementById('country_disp').value)

        var currentCountry = $( "#country" ).find('option:selected').data('eu');

        if (currentCountry == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry != undefined) {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
        }

    });

    $( "#country" ).change(function() {
        var selected = $(this).find('option:selected');
        var requiredVat = selected.data('eu'); 

        if (requiredVat == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else if (requiredVat == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
            $('#cfpiva').val("")
        }

    }); 
    
    function submitUserUpdateForm(form, event) {
        const $form = $(form);
        const notDisabledInputs = $form.find(':input').not(':disabled');
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                notDisabledInputs.prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS'], '', () => {
                        window.location.href = window.location.pathname;
                    });
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
                notDisabledInputs.prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form input').on('keydown', function(event) {
        if (event.key.toUpperCase() == 'ENTER') {
            event.preventDefault();
            $(this).closest('section').find('[type="submit"]').trigger('click');
        }
    });
    $('#user-update-data-form [type="submit"]').on('click', function(event) {
        event.preventDefault();

        const $form = $(this).closest('form');
        const enabledInputs = $form.find('[type="submit"], input:not(:disabled)');
        const sectionInputs = $(this).closest('section').find('input[name], textarea[name]').toArray();

        let sectionData = sectionInputs
            .map(input => ({ 
                name: $(input).attr('name'),
                value: encodeURIComponent($(input).val())
            }))
            .map(input => `${input.name}=${input.value}`)
            .join('&');

        let otherData = $form.find('input[name], textarea[name]').toArray()
            .filter(input => !sectionInputs.includes(input))
            .map(input => ({ 
                name: $(input).attr('name'),
                value: encodeURIComponent($(input).data('original-value') || $(input).val())
            }))
            .map(input => `${input.name}=${input.value}`)
            .join('&');
        
        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: [sectionData, otherData].join('&'),
            dataType: 'json',
            beforeSend: function() {
                enabledInputs.prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                    sectionInputs.forEach(input => {
                        let value = $(input).val();
                        $(input).data('original-value', value)
                    });
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
                enabledInputs.prop('disabled', false);

                const firmInputs = $form.find('.firm-input').toArray();
                firmInputs
                    .filter(input => $(input).data('original-value').length > 0)
                    .forEach(input => $(input).prop('disabled', true));

                if (firmInputs.every(input => $(input).is(':disabled'))) {
                    $('#firm-input-submit').remove();
                }
            }
        });
    });

    $('#user-update-dispatch-form, #user-update-invoice-form').validate({
        rules: {
            i_cap: { zip: true },
            d_cap: { zip: true }
        },
        submitHandler: submitUserUpdateForm,
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS'])
    })

    $('#1to2step').click(function(event) {
        var form = $('#create-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                $('#idnewotp').val(data.id);
                $('#qrcode').attr('src','data:image/png;base64, ' + data.data);
                $('#firstStep').hide();
                $('#secondStep').show();
                console.log(data);
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#enableOTP').click(function(event) {
        var form = $('#enable-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#deleteOTP').click(function(event) {
        var form = $('#delete-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#password-change-form').validate({
        rules: {
            current: 'required',
            password: {
                required: true,
                complexPassword: true
            },
            password_confirm: {
                required: true,
                equalTo: '#password'
            }
        },
        messages: {
            password_confirm: {
                equalTo: msg['ERR_PASSWORDCONFIRM']
            }
        },
        submitHandler: function(form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('#profile-consent-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });

    $('[name*="country"]').on('change', updateProvinces);
    updateProvinces();
}