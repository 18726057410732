$(document).ready(function() {

    jQuery.validator.addMethod(
        'complexPassword',
        function(value, element) {
            let complex = value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value);
            return this.optional(element) || complex;
        },
        msg['ERR_PASSWORDCOMPLEX']
    );

    jQuery.validator.addMethod(
      "regex",
      function(value, element, regexp) {
        const re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
      },
      "Please check your input."
    );

    jQuery.validator.addMethod(
        "zip",
        function(value, element) {
            return this.optional(element) || (function() {
                try {
                    const countryInput = element.closest('form').querySelector('[name*="country"]:not([type="hidden"]');

                    if (countryInput.value == 108) { // ITALY
                        return /^[0-9]{5}$/g.test(value);
                    }
                    else {
                        return true;
                    }
                }
                catch {
                    return true;
                }
            })();
        },
        msg['ERR_ZIP_FORMAT']
    );
    
    jQuery.validator.messages.required = msg['ERR_MISSINGFIELDS'];

})