function catalogPage() {
    'use strict';

    $('html').on('catalog:load', onCatalogLoad);

    onCatalogLoad();
    function onCatalogLoad() {
        // lightGallery
        $('.product-item__img.lightbox').lightGallery({
            download: false,
            prevHtml: '<img src="/skins/current-skin/images/icons/arrow-grey-slider-left.svg"  alt="prev" style="width: 22px"/>',
            nextHtml: '<img src="/skins/current-skin/images/icons/arrow-grey-slider-right.svg" alt="next" style="width: 22px"/>',
        });
        // lightGallery lightbox is created on the spot, try to bind event at .5s intervals
        $('.product-item__img.lightbox').on('click tap', function () {
            function disableContextMenu() {
                let lightbox = $('.lg');
                if (lightbox.length == 0) {
                    setTimeout(disableContextMenu, 500);
                }
                else {
                    lightbox.on('contextmenu', function (e) {
                        e.preventDefault();
                    });
                }
            }
            setTimeout(disableContextMenu, 500);
        });

        populateRemoveFilters();
        checkPreferredLayout();
        loadQtyInputs();
        addClickEvents();
        setCurrentFiltersHref();
        bindCatalogFilterEvents();
        removeEmptyOptionsFilter();

        $('.filters-wrapper').show();
        $('#catalog-container').removeClass('catalog-loading');
        $('html, body').removeClass('is-popup-open');
        $(".nano").nanoScroller()
        $(".nano-pane").css("display", "block");
        $(".nano-slider").css("display", "block");

        $('.catalog-search').on('input', function () {
            var input = $(this)
            var elements = $(this).parents('.filter-dropdown').find('li:not(.mt-8)')
            for (var i = 0; i < elements.length; i++) {
                if (!(elements[i].innerText.includes(input.val().toUpperCase()) || elements[i].innerText.includes(input.val().toLowerCase()) || elements[i].innerText.includes(input.val()[0].toUpperCase() + input.val().substring(1).toLowerCase()))) {
                    elements[i].classList.add('hide')
                } else {
                    elements[i].classList.remove('hide')
                }
            }
            input.parents('.search-container').siblings('.scroll-view').find('.toggle-subfilter').parent().each(function () {
                if ($(this).find('li:not(.hide)').length == 0) {
                    $(this).addClass('hide');
                } else {
                    $(this).removeClass('hide');
                }
            })
            if (input.parents('.search-container').siblings('.scroll-view').find('.toggle-subfilter').parent(':not(.hide)').length == 1) {
                input.parents('.search-container').siblings('.scroll-view').find('.toggle-subfilter').parent(':not(.hide)').addClass('open')
            } else {
                input.parents('.search-container').siblings('.scroll-view').find('.toggle-subfilter').parent().removeClass('open')
            }
        });

        $(".product-item__img, .product_brand").on('click tap', function () {
            const productItem = $(this).parents('.product-item');
            var code = productItem.data('openreveal');
            if (code) {
                loadProductModal(productItem.data('url'));
            }
            $('#product-slider-' + code).slick({
                speed: 500,
                autoplay: false,
                arrows: true,
                autoplaySpeed: 4500,
                pauseOnFocus: false,
                asNavFor: '#product-slider-nav',
            });

            $('#product-slider-nav-' + code).slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '#product-slider',
                dots: true,
                focusOnSelect: true
            });
        })

        $('#catalog-wrapper').on('click', '#filters-dropdown .toggle-subfilter', function (event) {
            event.preventDefault();
            $(this).parent('li').toggleClass('open');
            $(this).closest('.scroll-view').nanoScroller({ alwaysVisible: true });
            $(".nano-pane").css("display", "block");
            $(".nano-slider").css("display", "block");
            return false;
        });

        $('.mobile-popup-overlay').on('click', function (event) {
            event.preventDefault();

            $('.loading-master').removeClass('hide');
            $('body').addClass('block-scroll');

            if (exists('#catalog-container')) {
                createCookie('scrolltop', window.scrollY, 1);
                createCookie('prev-url', window.location.href, 1);
            }

            const href = $(this).attr('href');
            window.location.replace(href);
        });

        $('#filters-dropdown .filter-name').on('click', function () {
            var $thisScrollView = $(this).next(".filter-dropdown");
            resetDropdown($thisScrollView);
            if (!$(this).parent().hasClass("open")) {
                $thisScrollView.slideDown();
                $(this).parent().addClass("open");
                window.dispatchEvent(new Event('resize')); //Hack to make nanoscroller work
            }
            else {
                $thisScrollView.slideUp();
                $(this).parent().removeClass("open");
            }
            return false;
        });

        $('#filters-dropdown li > a').on('click', function (event) {
            event.preventDefault();
            var count = $('#filters-dropdown li.active').length;
            if (count >= 9 && !$(this).parent().hasClass('active')) {
                showMessageBox('danger', general_toomanyfilters);
                $('#filters-dropdown').focus();
            }
            else {
                $(this).parent().toggleClass('active');
                var url = $(this).attr('href');
                reloadCatalog(url, true);
            }
            return false;
        });

        // Product reveal
        $('#productreveal').on('load', function () {
            if ($(this).contents().find('body').html() !== '') {
                $('#productreveal')
                    .css({ display: 'block' })
                    .closest('.reveal-overlay')
                    .css({ display: 'flex' });

                $('#productreveal').contents().on('mousedown, mouseup, click', function (event) {
                    const target = $(event.target);
                    if (target.is('[type="submit"]:not(:disabled)')) {
                        setTimeout(getCartInfo, 500);
                        function getCartInfo() {
                            const cartInfo = $('#productreveal').contents().find('.body-container').attr('cart-info');
                            if (cartInfo) {
                                const [timestamp, updatedId, modelIds] = cartInfo.split(';').map(info => eval(info));
                                $('body').trigger('cartpopup:open', {
                                    timestamp: timestamp,
                                    updatedId: updatedId,
                                    modelIds: modelIds
                                });
                            }
                            else {
                                setTimeout(getCartInfo, 500);
                            }
                        }
                    }
                    else if (target.is('.sizes-table')) {
                        window.location.href = target.attr('href');
                    }
                    else if (target.is('.close-popup-product:not(.return-to-catalog)')) {
                        $('#productreveal')
                            .css({ display: 'none' })
                            .closest('.reveal-overlay')
                            .css({ display: 'none' });;
                        $('body').removeClass('block-scroll');
                    }
                });

                $('.loading-master').addClass('hide');
            }
        })
            .closest('.reveal-overlay').on('click', function (event) {
                const target = $(event.target);
                if (!target.is('#productreveal') && !target.closest('#productreveal').length > 0) {
                    $(this).css({ display: 'none' });
                    $('#productreveal').css({ display: 'none' });
                    $('body').removeClass('block-scroll');
                }
            });

        $('body').removeClass('block-scroll');
    }

    // catalog layout
    function checkPreferredLayout() {
        let layout = localStorage.getItem('catalogLayout');

        if (layout && layout === 'list') {
            $('#list-option').prop('checked', true);
            $('#list-option-mobile').prop('checked', true);
        } else {
            layout = 'grid';
            $('#grid-option').prop('checked', true);
            $('#grid-option-mobile').prop('checked', true);
        }

        setCatalogLayout(layout);
    }

    function setCatalogLayout(layout) {
        if (layout === 'grid') {
            $('#catalog-wrapper .list-layout').hide();
            $('#catalog-wrapper .grid-layout').fadeIn();
        } else {
            $('#catalog-wrapper .grid-layout').hide();
            $('#catalog-wrapper .list-layout').fadeIn();
        }

        localStorage.setItem('catalogLayout', layout);
    }

    function addClickEvents() {
        $('#layout-options input[type="radio"], #layout-options-mobile input[type="radio"]').on('click', function () {
            setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
        });

        $('#sort-options')
            .on('click', function () {
                const dropdown = $(this).find('.sort-dropdown');
                if (!dropdown.data('hover'))
                    dropdown.toggle();
            })
            .on('mouseover', function () {
                const dropdown = $(this).find('.sort-dropdown');
                dropdown.show().data('hover', true);
            })
            .on('mouseleave', function () {
                const dropdown = $(this).find('.sort-dropdown');
                dropdown.hide().data('hover', false);
            });
    }

    function reloadCatalog(url, push) {
        $('#catalog-container').addClass('catalog-loading');
        $.ajax({
            url: url,
            type: "GET",
            dataType: "html",
            complete: function (jqXHR) {
                const response = $("<div>").append($.parseHTML(jqXHR.responseText));

                $('#catalog-container').html(response.find("#catalog-wrapper"));
                $('#sort-options .sort-dropdown').replaceWith(response.find("#sort-options .sort-dropdown"));

                $('#catalog-wrapper').find('#productreveal').remove();

                if (push) {
                    window.history.pushState(null, document.title, url);
                }
                $('body').trigger('catalog:load');
            }
        });
    }

    function resetDropdown($excluded) {
        $('.catalog-search').val("")
        $(".catalog-search").trigger("input");
        var $dropDowns = $('#catalog-wrapper #filters-dropdown .filter-dropdown').not($excluded);
        $dropDowns.slideUp();
        $dropDowns.parent().removeClass("open");
        $dropDowns.find(".active").not(".active-original").removeClass("active");
        $dropDowns.find(".active-original").not(".active").addClass("active");
    }

    function populateRemoveFilters() {
        $('.filters-wrapper li.active > a').each(function () {
            var id = $(this).data('ref');
            var url = $(this).attr('href');
            $(document.getElementById(id)).attr('href', url);
        });
        $('.filters-wrapper > a').each(function () {
            if ($(this).attr('href') === '#') {
                $(this).addClass('disabled');
                $(this).children('span.fa').remove();
            }
        });
    }

    function setCurrentFiltersHref() {
        $('.current-filter').each(function () {
            let [urlBase, urlParams] = window.location.href.split('?');

            if ($(this).hasClass('remove-all')) {
                urlParams = urlParams.split('&').filter(param => !param.includes('tag')).join('&');
                $(this).attr('href', urlBase + '?' + urlParams);
            }
            else if ($(this).hasClass('url-page')) {
                const urlvalue = $(this).data('urlvalue');
                urlBase = urlBase.split('/').filter(index => !decodeURIComponent(index.replace(/\+/g, '%20')).includes(urlvalue)).join('/');
                if (urlParams) {
                    $(this).attr('href', urlBase + '?' + urlParams);
                } else {
                    $(this).attr('href', urlBase);
                }
            }
            else {
                const urlkey = $(this).data('urlkey');
                urlParams = urlParams.split('&').filter(param => decodeURIComponent(param.replace(/\+/g, '%20')) != urlkey).join('&');
                $(this).attr('href', urlBase + '?' + urlParams);
            }

            // Workaround for nanoscroller related bug
            $(this).on('click', function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                let href = $(this).attr('href');
                window.location.href = href;
            })
        });
    }

    function bindCatalogFilterEvents() {
        // Mobile catalog filters
        $('#toggle-filter').on('click tap', function () {
            $('body').addClass('block-scroll');
            $('#mobile-filters-panel').addClass('open');
        });

        $('#toggle-sort-options').on('click tap', function () {
            $('body').addClass('block-scroll');
            $('#mobile-sort-options-panel').addClass('open');
        });

        $('#mobile-filters-panel .overlay, #mobile-sort-options-panel .overlay').on('click tap', function () {
            $('body').removeClass('block-scroll');
            $(this).parent().removeClass('open');
        });

        $('#mobile-filters-panel .filter .filter-list .show-all').on('click tap', function () {
            $(this).closest('.filter-list').find('.hide').removeClass('hide');
            $(this).hide();
        });

        $('#mobile-filters-panel .filter .filter-title').on('click tap', function () {
            $(this).closest('.filter').toggleClass('open');
        });

        $('#mobile-filters-panel .toggle-subfilter').on('click tap', function () {
            $(this).closest('.sublist').toggleClass('open');
        });
    }

    // search result
    if (window.location.search != '') {
        if (document.getElementById('search-result') != undefined) {
            $('#search-result').removeClass('hide');
            document.getElementById('results-search').innerHTML = decodeURIComponent(GetUrlValue('search'));
        }
    }
    else {
        if ($('#search-result') != undefined) {
            $('#search-result').addClass('hide');
        }
    }
}

function getUrlParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
}

const productPopupTimeout = 45000;

function loadProductModal(url) {
    const timestamp = Date.now();

    $('.right-menu-desktop-content').addClass('fix-bug-marginleft')
    $('.loading-master').removeClass('hide').data('timestamp', timestamp);
    $('body').addClass('block-scroll');

    setTimeout(function () {
        const spinner = $('.loading-master');
        if (!spinner.hasClass('hide') && spinner.data('timestamp') == timestamp) {
            spinner.addClass('hide');
            $('body').removeClass('block-scroll');
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
    }, productPopupTimeout);

    $('#productreveal').attr('src', `${url}?popup=true`);
}

function removeEmptyOptionsFilter() {
    $('.options-filter').each(function () {
        if ($(this).find('ul li').length == 0) {
            $(this).remove();
        }
    });
}