'use strict';

var dropCookie = true;


function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

$(document).ready(function() {
    if (getUrlParam('errorCode') == '263') {
        $('#login-form').addClass('hide');
        $('#login-form-otp').removeClass('hide');
    }

    var anchors = document.querySelectorAll('a[href^="#"]');
    for (i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            var element = document.querySelector(this.getAttribute('href'));
            var headerOffset = 121 + 84;
            if (Foundation.MediaQuery.is('large')) {
                headerOffset = 60 + 84;
            }
            var elementPosition = element.getBoundingClientRect().top + window.scrollY;
            var offsetPosition = elementPosition - headerOffset;;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });  
        });
    }

    if (sessionStorage.getItem("side-popup") == null) {
        if (exists('#side-popup')) {
            setTimeout(function(){ 
                sessionStorage.setItem("side-popup", "true");
                $('#side-popup').removeClass('hide');
            }, 20000);
        }
    }

    const reveallanguage = $('#revealanguage');
    if (!checkCookie('languageChoosen') && reveallanguage.length > 0) {
        reveallanguage.foundation('open');
        createCookie('languageChoosen', 'true')
    }

    $('#close-side-popup').on('click', function() {
        $('#side-popup').addClass('hide');
    })

    $('.faq .toggle').on('click', function () {
        const faq = $(this).parent();
        $('.faq').not(faq)
            .removeClass('open')
            .find('.answer').slideUp();
        faq
            .toggleClass('open')
            .find('.answer').slideToggle();
    });
});

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
  
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
  
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;
  
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return true
    } catch (err) {
      document.body.removeChild(textArea);
      return false
    }
}

function exists(selector) {
    try {
        return $('html').find(selector).length > 0;
    }
    catch {
        return false;
    }
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function(data) {
                resolve(data.results);
            },
            error: function() {
                resolve([]);
            }
        });
    });
}
    
function updateProvinces() {
    $('[name*="prov"]').not('[readonly]').not('[disabled]').each(function() {
        const $this = $(this);
        const country = $this.closest('form').find('[name*="country"]');

        if (country.length > 0) {
            loadProvinces(country.val()).then(function(provinces) {
                if (provinces.length > 0) {
                    const id = $this.attr('id');
                    const name = $this.attr('name');
                    const type = $this.attr('type');
                    const value = $this.val();
                    const placeholder = $this.attr('placeholder');
                    let options = [];
                    let hasSelectedValue = false;

                    for (let i = 0; i < provinces.length; i++) {
                        const province = provinces[i];
                        const selected = (province.code == value) ? 'selected' : '';
                        options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                        hasSelectedValue = hasSelectedValue || (province.code == value);
                    }
                    if (!hasSelectedValue) {
                        options[0].replace('  ', ' selected');
                    }
                    $this.replaceWith(`
                        <select id="${id}" name="${name}" data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                            ${options.join('\n')}
                        </select>
                    `);
                }
                else if ($this.is('select')) {
                    const id = $this.attr('id');
                    const name = $this.attr('name');
                    const type = $this.data('type');
                    const value = $this.data('value');
                    const placeholder = $this.data('placeholder');
                    $this.replaceWith(`<input id="${id}" type="${type}" name="${name}" value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                }
            });
        }
    });
}

var scrolltop = checkCookie('scrolltop');
var prevUrl = checkCookie('prev-url');
if (scrolltop && prevUrl == window.location.href) {
  window.scrollTo({
    top: scrolltop,
    behavior: 'auto'
  });
}

function getQueryParams(url) {
    let [_, query] = url.split('?');
    let params = {};

    if (query) {
        query.split('&').forEach(param => {
            let [key, value] = param.split('=');
            params[key] = value;
        });
    }
    params['hash'] = window.location.hash;

    return params;
}

function setQueryParams(url, params) {
    let urlBase = url.split('?')[0];
    let query = [];

    for (let key of Object.keys(params)) {
        if (key != 'hash') {
            query.push(`${key}=${params[key]}`);
        }
    }
    query = query.join('&');
    if (params['hash']) {
        query += params['hash'];
    }

    return `${urlBase}?${query}`;
}

function GTMCallback(callback) {
    return (containerId) => {
        /**
         * If trackers are blocked containerId === undefined
         * Google Tag Manager container id starts with "GTM-"
         */
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}