function productComponent() {
    'use strict';

    $('html').on('cartpopup:open', onCartPopupOpen);

    loadQtyInputs();
    initProductPageSwipers();

    $('.close-popup-product.return-to-catalog').on('click', function(event) {
        event.preventDefault();

        const href = checkCookie('prev-url');
        window.location.replace(href);
    });

    $('.qty-max').on('click', function () {
        var $this = $(this);
        var inputName = $this.attr('data-input');
        var input = $('.i-number[name="' + inputName + '"]');
        var inputMax  = parseInt(input.attr('data-max'));
        var availability = $('#' + inputName);

        input.val(inputMax);
        availability.text(0);

        $('#add-to-cart').prop('disabled', false);
        calculateTotal();
    });

    $('#qty-max-all').on('click', function () {
        $('.qty-max').each(function () {
            $(this).trigger('click');
        });
    });

    calculateTotal();

    $('.size').click(function(event) {
        $(this).parents('.size-div').siblings('.price-div').find('.price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('.tax-price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('.save-price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('#price').addClass('hide');
        var id = $(this).attr('data-size');
        var wishlist_models =  $(this).parents('form').find('.wishlist-models').val().split(' ');
        var wishlist_present = wishlist_models.includes(id);
        if (wishlist_present) {
            $(this).parents('form').find('.div-already-wishlist').removeClass('hide');
            $(this).parents('form').find('.div-to-wishlist').addClass('hide');
        } else {
            $(this).parents('form').find('.div-already-wishlist').addClass('hide');
            $(this).parents('form').find('.div-to-wishlist').removeClass('hide');
        }
        $('#price-' + id).removeClass('hide');
        $('#tax-price-' + id).removeClass('hide');
        $('#save-price-' + id).removeClass('hide');

        if (parseInt($('input[name=size]:checked').attr('data-availability')) < parseInt($('.quantity-container').val())) {
            $('.contact-no-quantity').removeClass('hide');
        }

        if (parseInt($('input[name=size]:checked').attr('data-availability')) >= parseInt($('.quantity-container').val())) {
            $('.contact-no-quantity').addClass('hide');
        }

    });

    $('#catalog-container .addtocart-form, .product-container .addtocart-form').on('submit', function(event) {
        event.preventDefault();
        const form = $(this);
        const selected = '#' + $('.product-sizes input:checked').attr('id');

        if (selected === '#no-size-selected') {
            showMessageBox('danger', msg['ERR_NOSIZESELECTED']);
        }
        else {
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                dataType: 'json',
                beforeSend: function() {
                    form.find('input, [type=submit]').prop('disabled', true);
                },
                success: function(data) {
                    if (!data.success) {
                        if (data.code === 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code === 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code === 'LOGIN_REQUIRED') {
                            location.href = '/' + $('html').attr('lang') + '/login';
                        }
                        else {
                            showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                        }
                    }
                    else {
                        form.find('input').prop('disabled', false);
                        rewixUpdateCart(form);
                        showCartPopup(form);
                        updateCurrentQtys(form);
                    }
                },
                error: function() {
                    form.find('input').prop('disabled', false);
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                },
                complete: function() {
                    const qtyModel = form.find('[name*=qty_model]');
                    if (qtyModel.length == 1 && qtyModel.is(':hidden')) {
                        form.find('input, [type=submit]').prop('disabled', false);
                    }
                }
            });
        }
    });

    bindCartPopupEvents();
}

const cartPopupTimeout = 3000;

function bindCartPopupEvents() {
    $('#cart-popup-modal .close-icon').on('click tap', function() {
        const timestamp = $('#cart-popup-modal').data('timestamp');
        $('#cart-popup-modal').addClass('blocked-event').removeClass('in-focus');
        hideCartPopup(timestamp);
    });

    $('#cart-popup-modal')
        .on('mouseover', function () {
            $(this).addClass('in-focus');
            if ($(this).hasClass('animate__fadeOut')) {
                $(this).removeClass('animate__fadeOut').addClass('animate__fadeIn');
            }
        })
        .on('mouseleave', function () {
            const timestamp = Date.now();
            $(this).removeClass('in-focus').data('timestamp', timestamp);
            setTimeout(function () {
                if (!$('#cart-popup-modal').hasClass('hide') && !$('#cart-popup-modal').hasClass('in-focus')) {
                    hideCartPopup(timestamp);
                }
            }, cartPopupTimeout);
        });

    // Workaround to prevent parent scrolling for browsers that don't support overscroll-behavior
    if (CSS.supports('overscroll-behavior-y', 'contain') == false) {
        $('#cart-popup-modal .product-row').parent().on('DOMMouseScroll mousewheel', function(ev) {
            var $this = $(this),
                scrollTop = this.scrollTop,
                scrollHeight = this.scrollHeight,
                height = $this.innerHeight(),
                delta = (ev.type == 'DOMMouseScroll' ?
                    ev.originalEvent.detail * -40 :
                    ev.originalEvent.wheelDelta),
                up = delta > 0;
        
            var prevent = function() {
                ev.stopPropagation();
                ev.preventDefault();
                ev.returnValue = false;
                return false;
            }
        
            if (!up && -delta > scrollHeight - height - scrollTop) {
                // Scrolling down, but this will take us past the bottom.
                $this.scrollTop(scrollHeight);
                return prevent();
            } else if (up && delta > scrollTop) {
                // Scrolling up, but this will take us past the top.
                $this.scrollTop(0);
                return prevent();
            }
        });        
    }
}

function onCartPopupOpen(event, params) {
    params = params || {};
    const timestamp = params.timestamp || 0;
    const updatedId = params.updatedId || '';
    const modelIds = params.modelIds || [];

    if ($('#cart-popup-modal').data('timestamp') == timestamp) {
        event.preventDefault();
        return;
    }
    
    if ($('#cart-popup-modal').is(':visible')) {
        $('#cart-popup-modal').removeClass('animate__fadeIn').addClass('hide animate__fadeOut');
    }
    
    $('#cart-popup-modal').data('timestamp', timestamp);

    $.ajax({
        method: 'GET',
        url: window.location.href,
        dataType: 'html',
        success: function(data) {
            const response = $('<div>').append(data);

            if (exists('#cart-count-icon')) {
                $('#cart-count-icon').replaceWith(response.find('#cart-count-icon'));
            }
            else if (exists('#cart-popup-modal')) {
                $('#cart-popup-modal').replaceWith(response.find('#cart-popup-modal'));
            }
            $('#mobile-cart-icon').replaceWith(response.find('#mobile-cart-icon'));
            displayPopup();
        }
    })

    function displayPopup() {
        bindCartPopupEvents();
        $('#cart-popup-modal')
            .data('timestamp', timestamp)
            .removeClass('hide animate__fadeOut')
            .addClass('animate__fadeIn')
            .find('.product-row').each(function() {
                let id = $(this).data('id');
                if (id != updatedId) {
                    $(this).remove();
                }
            });
        $('#cart-popup-modal .model-info').each(function() {
            var id = $(this).data('id');
            if (!modelIds.includes(id)) {
                $(this).remove();
            }
        });
        if ($('#cart-popup-modal .model-info').length == 0) {
            $('#cart-popup-modal .product-row').remove();
        }

        if ($('#cart-popup-modal .product-image').is(':visible')) {
            $('#cart-popup-modal .product-image img').on('load error', function() {
                const infoHeight = Math.max(96, $('#cart-popup-modal .product-info').height());
                const imageHeight = $(this).height();
                const maxInfoHeight = Math.max(250, imageHeight);
                // Activate scroll
                if (infoHeight >= maxInfoHeight) {
                    $('#cart-popup-modal .model-info-list').attr('style', 'max-height: ' + maxInfoHeight + 'px; overflow-y: scroll; overscroll-behavior-y: contain;');
                }
                // Cause image to float on top
                if (infoHeight > imageHeight) {
                    $('#cart-popup-modal .product-image').height(Math.min(infoHeight, maxInfoHeight));
                }
                else {
                    $('#cart-popup-modal .product-info').height(imageHeight);
                }
                setTimeout(function() {
                    if (!$('#cart-popup-modal').hasClass('hide') && !$('#cart-popup-modal').hasClass('in-focus')) {
                        hideCartPopup(timestamp);
                    }
                }, cartPopupTimeout);
            });
        }
        else {
            setTimeout(function() {
                if (!$('#cart-popup-modal').hasClass('hide') && !$('#cart-popup-modal').hasClass('in-focus')) {
                    hideCartPopup(timestamp);
                }
            }, cartPopupTimeout);
        }
    }

}

function showCartPopup(form) {
    const timestamp = Date.now();
    const updatedId = $(form).data('id');
    const modelIds = $(form).find('.quantity-container').toArray()
        .filter(function(input) {
            return $(input).data('current') != $(input).val();
        })
        .map(function(input) {
            return $(input).data('id');
        });

    if (!exists('#cart-popup-modal')) {
        $('.body-container').attr('cart-info', `${timestamp};${updatedId};[${modelIds}]`);
    }
    else {
        $('body').trigger('cartpopup:open', {
            timestamp: timestamp,
            updatedId: updatedId,
            modelIds: modelIds
        });
    }
}

function hideCartPopup(timestamp) {
    if ($('#cart-popup-modal').data('timestamp') == timestamp) {
        $('#cart-popup-modal').removeClass('animate__fadeIn').addClass('animate__fadeOut');
        setTimeout(function() {
            if (!$('#cart-popup-modal').hasClass('in-focus')) {
                $('#cart-popup-modal').addClass('hide').removeClass('blocked-event');
            }
        }, 1000);
    }
}

function updateCurrentQtys(form) {
    for (let input of $(form).find('.quantity-container').toArray()) {
        let qty = $(input).val();
        $(input).data('current', qty);
    }
    $(form).find('input[type="submit"').prop('disabled', true);
}

function calculateTotal() {

    // Calculate availability total
    var availabilityTotal = 0;

    $('.availability').each(function () {
        availabilityTotal += parseInt($(this).text());
    });

    $('#availability-total').text(availabilityTotal);


    // Calculate order total
    var orderTotal = 0;

    $('.i-number').each(function () {
        orderTotal += parseInt($(this).val());
    });

    $('#order-total').text(orderTotal);


    // Calculate price total
    var singlePrice = parseFloat($('#price').text()).toFixed(2);
    var priceTotal = parseFloat(orderTotal * singlePrice).toFixed(2);
    $('#price-total').text(priceTotal + "€");
}

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if ($this.val() > $this.data('max')) {
            $this.css('color', 'red');
        }

        if (!$this.data('initialized')) {
            $('<button class="i-number-btn i-number-minus" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-minus.svg"/></button>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<button class="i-number-btn i-number-plus" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-plus.svg"/></button>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                const min = $this.data('min') >> 0;
                const max = $this.data('max') == null ?  Number.MAX_SAFE_INTEGER : $this.data('max');

                if (value > max) {
                    $this.val(max);
                }
                else if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                $this.trigger('change');
            }

            $this.on('change', function() {
                const current = $this.val();
                const max = $this.data('max') == null ?  Number.MAX_SAFE_INTEGER : $this.data('max');

                if (current <= max) {
                    $this.css('color', '');
                }
                else {
                    $this.css('color', 'red');
                }

                checkSubmitButton();
            });

            function checkSubmitButton() {
                const $form = $this.closest('form');
                const qtyChanged = $form.find('.i-number').toArray()
                    .some(input => $(input).data('current') != $(input).val());
                const button = $form.find('[type="submit"]');

                if (qtyChanged) {
                    button.prop('disabled', false);
                }
                else {
                    button.prop('disabled', true);
                }
            }
        }
    });
}

function initProductPageSwipers() {
    var swiper = initSwiper('.swiper-product', {
      autoplay: false,
      autoHeight: true,
      spaceBetween: 30,
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      mousewheel: {
        forceToAxis: true,
        invert: true,
      },
      loop: true,
    }
    );
    bindSwiperPagination(swiper);
    
    initSwiper('.swiper-product-pagination', {
      speed: 400,
      autoplay: true,
      autoHeight: true,
      spaceBetween: 30,
      slidesPerView: 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }