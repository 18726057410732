function initSwiper(selector, params) {
    params = params || {
        speed: 400,
        autoplay: true,
        autoHeight: true,
        spaceBetween: 30,
        slidesPerView: 1,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        },
        mousewheel: {
        forceToAxis: true,
        invert: true,
        },
        loop: true,
    };

    const swiper = new Swiper(selector, params);
    return swiper;
}

function bindSwiperPagination(swiper) {
    const pagination = $(swiper.el).siblings('.swiper-product-pagination');
    if (pagination.length > 0) {
        $(pagination).on('click tap', '.bullet', function() {
        var index = $(this).data('index');
        swiper.slideToLoop(index);
        });
        swiper.on('slideChange', function() {
        const bullets = pagination.find('.bullet');
        const currentBullet = $(`.bullet[data-index=${swiper.realIndex}]`);
        bullets.removeClass('current');
        currentBullet.addClass('current');
        });
    }
}