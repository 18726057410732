function subscribePage() {

    $('.open-subscribe').click(function() {
        $('.subscribe-div').slideToggle()
        $('.login-div').slideToggle()
        $('.upper-box > .open-subscribe > .title-login').toggleClass('hide')
        $('.login-arrow').toggleClass('rotate-180')
    })

    if (getUrlParam('subscribe') == 'true') {
        $('.subscribe-div').slideToggle()
        $('.login-div').slideToggle()
        $('.upper-box > .open-subscribe > .title-login').toggleClass('hide')
        $('.login-arrow').toggleClass('rotate-180')
    }

}