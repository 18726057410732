function contactFormComponent() {

    $('.contact-form').validate({
        submitHandler: sendContact,
        errorPlacement: () => {},
    });

    $.getScript('https://www.google.com/recaptcha/api.js');
}

function findCheckedInput(name) {

    var result = '';
    var x = document.getElementsByName(name);
    var i;
        for (i = 0; i < x.length; i++) {
            if (x[i].checked == true) {
                result = result.concat(x[i].value) + ' '    
            }
        }
    return result;
}

function sendRequestCaptcha() {
    $('#contact-no-quantity input[type=submit]').prop('disabled', false);
    sendRequest();
}

function sendRequest() {
    var $form = $('#contact-no-quantity :input, #contact-no-quantity textarea');

    if (document.getElementById('email-contact').value != "") {

    $.ajax({
        type: "POST",
        url: "/restful/delegate/form/save",
        data: $form.serialize(),
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded',
        beforeSend: function(data) {

        },
        success: function(data) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            grecaptcha.reset();
        },
        complete: function(data) {
        }
    });

    } else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        grecaptcha.reset();
    }

}

function contactFormRecaptchaBusiness() {

    $('.contact-form-professionista').validate({
        errorPlacement: function(error, element) {
            error.appendTo("");
          }
    });

    if ($("#privacy-professionista").is(":checked") == true) {

    if ($('.contact-form-professionista').valid()) {

    $.ajax({
        type: $('.contact-form-professionista').attr('method'),
        url: $('.contact-form-professionista').attr('action'),
        data: $('.contact-form-professionista').serialize(),
        headers: {'Accept': "application/json"},
        beforeSend: function(data) {
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })
 
    } else {
        grecaptcha.reset();
    }

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }

}

function contactRecaptchaCallbackPrivate() {

    if ($("#privacy-private").is(":checked") == true) {

    $.ajax({
        type: $('#private-contact').attr('method'),
        url: $('#private-contact').attr('action'),
        data: $('#private-contact').serialize(),
        headers: {'Accept': "application/json"},
        beforeSend: function(data) {
        },
        success: function(data) {
            if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            $('#private-contact-submit').addClass('private-button-disabled')
            } else {
            showMessageBox('danger', data.errors.message);
            }
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            $('#private-contact').foundation('close');
            grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }
}

function sendContact(formSelector) {
    const $form = $(formSelector);
    if ($form.valid()) {
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            beforeSend: function(data) {
    
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                reloadRecaptcha();
            }
        });
    }
    else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        reloadRecaptcha();
    }
}

function contactFormRecaptcha() {
    $('.contact-form').trigger('submit');
}

function expiredContactFormRecaptcha(idx) {
    $(`#g-recaptcha-${idx}`).closest('form').find('[type="submit"]').prop('disabled', true);
}

function reloadRecaptcha(idx) {
    const widgetId = $(`#g-recaptcha-${idx}`).data('widgetId');
    grecaptcha.reset(widgetId);
}