function menuComponent() {

    // DESKTOP

    $('.main-menu-item:not(.link)').on('click tap', function() {
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected');
            closeSubmenus();
        }
        else {
            $('.main-menu-item.selected').removeClass('selected');
            $(this).addClass('selected');
            showSubmenus($(this).data('target'));
        }
    });

    $('.submenu-overlay').on('click tap', closeSubmenus);

    function showSubmenus(target) {
        const targetSubmenu = $(`.submenu[data-ref=${target}]`);
        $('.main-menu').addClass('open');
        $('#submenus-container').removeClass('hide');
        $('.submenu').addClass('hide');
        $(targetSubmenu).removeClass('hide');
        $('.subsubmenu').addClass('hide');
        showSubsubmenu($(targetSubmenu).find('.submenu-item.selected').data('target'));
        $('body').addClass('no-scroll');
    }

    function closeSubmenus() {
        $('.main-menu').removeClass('open');
        $('.main-menu-item.selected').removeClass('selected');
        $('#submenus-container').addClass('hide');
        $('.subsubmenu').addClass('hide');
        $('body').removeClass('no-scroll');
    }

    $('.submenu-item:not(.kids):not(.mobile):not(.see-all').on('mouseover click tap', function() {
        if (! $(this).hasClass('selected')) {
            $('.submenu-item.selected').removeClass('selected');
            $(this).addClass('selected');
            showSubsubmenu($(this).data('target'));
        }
    });

    $('.submenu-item.kids').on('mouseover click tap', function() {
        if (! $(this).hasClass('selected')) {
            const target = $(this).data('target');
            const container = $(this).parents('.columns-container');
            const subsubmenus = container.find('.subsubmenu');
            const targetSubsubmenu = container.find(`[data-ref=${target}]`);
    
            $('.submenu-item.kids.selected').removeClass('selected')
            $(this).addClass('selected');
            subsubmenus.addClass('hide');
            targetSubsubmenu.removeClass('hide');
        }
    });

    function showSubsubmenu(target) {
        const targetSubsubmenu = $(`#submenus-container .subsubmenu[data-ref=${target}]`);
        $('#submenus-container .subsubmenu').addClass('hide');
        $(targetSubsubmenu).removeClass('hide');
    }



    // MOBILE

    $('#submenus-container-mobile').fadeOut();
    $('#subsubmenus-container-mobile').fadeOut();
    $('#kids-subsubmenus-container-mobile').fadeOut();
    $('.mobile-first-image').fadeOut();
    $('.mobile-first-image[data-ref=default]').fadeIn();
    $('.mobile-first-image').each(function() {
        const ref = $(this).data('ref');
        var href = '#';
        if (ref != 'default') {
            href = $(`.submenu.mobile[data-ref=${ref}]`).find('.submenu-item.see-all a').attr('href');
        }
        $(this).attr('href', href);
    });
    
    $('.mobile-menu-view [data-image]').on('click tap', function() {
        const target = $(this).data('image');
        $('.mobile-first-image').fadeOut();
        $(`.mobile-first-image[data-ref=${target}]`).fadeIn();
    });

    $('.mobile-menu-item:not(.link)').on('click tap', function() {
        var target = $(this).data('target');
        openMobileSubmenu(target);
    });

    function openMobileSubmenu(target) {
        $('#submenus-container-mobile .submenu').addClass('hide');
        $(`#submenus-container-mobile .submenu[data-ref=${target}]`).removeClass('hide');

        $('#mobile-menu').fadeOut();
        $('#submenus-container-mobile').fadeIn();
    }

    $('#submenus-container-mobile .submenu-item:not(.see-all)').on('click tap', function() {
        var target = $(this).data('target');
        openMobileSubsubmenu(target);
    });

    // kids
    $('#subsubmenus-container-mobile .submenu-item:not(.see-all)').on('click tap', function() {
        var target = $(this).data('target');
        openMobileSubsubmenu(target, '#kids-subsubmenus-container-mobile', '#subsubmenus-container-mobile');
    });

    function openMobileSubsubmenu(target, container='#subsubmenus-container-mobile', origin="#submenus-container-mobile") {
        const targetSubsubmenu = $(container).find(`.subsubmenu[data-ref=${target}]`);
        $(container).find('.subsubmenu').addClass('hide');
        $(origin).fadeOut();
        $(targetSubsubmenu).removeClass('hide');
        $(targetSubsubmenu).find('items').fadeOut();
        $(container).fadeIn();
    }

    $('.close-mobile-submenu').on('click tap', function() {
        const targetId = '#' + $(this).data('target');
        $(this).parent().fadeOut();
        $(targetId).fadeIn();
    });


    $('.open-menu-mobile').on('click tap', openMenuMobile)
    $('.close-menu-mobile').on('click tap', closeMenuMobile);

    function openMenuMobile() {
        $('.open-menu-mobile').addClass('unclickable');
        $('.close-menu-mobile').addClass('unclickable');
        $('.mobile-menu-view').removeClass('animate__fadeOut');
        $('.mobile-menu-view').addClass('animate__fadeIn');
        $('.mobile-menu-view').removeClass('hide');
        $('body').addClass('blocked-scrolling')
        $('.open-menu-mobile').addClass('hide');
        $('.close-menu-mobile').removeClass('hide');
        setTimeout(function() {
            $('.open-menu-mobile').removeClass('unclickable');
            $('.close-menu-mobile').removeClass('unclickable');
        }, 1000);
    }

    function closeMenuMobile() {
        $('.open-menu-mobile').addClass('unclickable');
        $('.close-menu-mobile').addClass('unclickable');
        $('.mobile-menu-view').removeClass('animate__fadeIn');
        $('.mobile-menu-view').addClass('animate__fadeOut');
        $('body').removeClass('blocked-scrolling')
        $('.close-menu-mobile').addClass('hide');
        $('.open-menu-mobile').removeClass('hide');
        setTimeout(() => {
            $('.mobile-menu-view').addClass('hide');
            $('.open-menu-mobile').removeClass('unclickable');
            $('.close-menu-mobile').removeClass('unclickable');
        }, 1000);
    }
    
    $ ('#close-currency').on('click tap', function () {
        $('#revealCurrency').foundation('close');
    });

    $ ('#close-language').on('click tap', function () {
        $('#revealanguage').foundation('close');
    });

    $('.mobile-menu-currency').on('click tap', closeMenuMobile);
    $('.mobile-menu-language').on('click tap', closeMenuMobile);


    // BREADCRUMB
    
    $('.mobile-menu-view [data-breadcrumb]').on('click tap', function() {
        const last = $('#mobile-menu-breadcrumb').data('last') + 1;
        const data = $(this).data('breadcrumb').toUpperCase();
        const element = $(`<div data-index="${last}" style="display:none">${data}</div>`);
        $('#mobile-menu-breadcrumb').append(element).data('last', last);
        element.fadeIn();
        if (last > 1) {
            const separator = $(`<span class="separator" data-index=${last} style="display:none">/</span>`)
            $(`#mobile-menu-breadcrumb [data-index=${last - 1}]`).append(separator);
            separator.fadeIn();
        }
        setTimeout(function() {
            const elementWidth = $('#mobile-menu-breadcrumb *').toArray()
                .map(elem => $(elem).outerWidth())
                .reduce((x, y) => x + y);
            const innerMargin = $('#mobile-menu-breadcrumb .separator').length * 16;
            const realBreadcrumbWidth = elementWidth + innerMargin;

            if ($('#mobile-menu-breadcrumb').outerWidth() < realBreadcrumbWidth && !element.html().includes(' ')) {
                let html = element.html();
                let index = (html.length/2) >> 0;
                while ("aeiouyAEIOUY".indexOf(html.charAt(index)) != -1) {
                    index += 1;
                }
                element.html(html.substring(0, index) + '- ' + html.substring(index))
            }
        }, 200);
    });

    $('.mobile-menu-view [data-breadcrumb-back]').on('click tap', function() {
        const last = $('#mobile-menu-breadcrumb').data('last');
        const elements = $(`#mobile-menu-breadcrumb [data-index=${last}]`);
        elements.fadeOut();
        setTimeout(() => elements.remove(), 400);
        $('#mobile-menu-breadcrumb').data('last', Math.max(0, last - 1));
    });
}