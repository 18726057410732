function profileOrders() {
    'use strict';

    // show/hide order controls
    $.fn.showControls = function() {
        return this.each(function() {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).show();
            $this.addClass('selected');
            $this.find('.order-row-indicator').addClass('fa-rotate-90');
        });
    };

    $.fn.hideControls = function() {
        return this.each(function() {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).hide();
            $this.removeClass('selected');
            $this.find('.order-row-indicator').removeClass('fa-rotate-90');
        });
    };

    $('.order-row').on('click', function(event) {
        var $this = $(this);
        if ($this.hasClass('selected')) {
            $this.hideControls();
        } else {
            // remove selected class from other rows
            $this.parent().find('.order-row.selected').hideControls();
            $this.showControls();
            $('html, body').animate({
                scrollTop: $this.position().top
            }, 200);
        }
    });

    // top buttons
    $('.edit-order').on('click', function(event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/delegate/easydropshipping/bookedtocart',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location = '/current/cart';
                } else {
                    alert('Cannot edit this order.');
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.delete-order').on('click', function(event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/cancel',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.reload();
                } else {
                    alert('Cannot cancel this order.');
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.pay-order').on('click', function(event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/initialize',
            data: { orderId: $this.attr('data-order-id') },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location = '/current/checkout/payment';
                } else {
                    alert('Cannot pay this order.');
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    // return form
    $('.show-return-form').click(function(event) {
        event.preventDefault();
        var $this = $(this);
        var target = $this.attr('data-target');

        $(target).attr('style','display:table-row')
    });

    $('.new-return-request form').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $(this).prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    //TODO translate
                    showMessageBox('success', msg['MSG_RETURN_REQUEST_SUCCESS']);
                }
                else if (data.errors.message.includes('not enough')) {
                    showMessageBox('danger', msg['MSG_ALREADY_RETURNED']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $(this).prop('disabled', false);
            }
        });
    });

    $('form.cancel-return-request-form').on('submit', function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/profile/returnrequests/1';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.upload-attachment-form button').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        const $form = $(this).closest('form');
        $form.find('[name=seller_invoice]').trigger('click');
    });

    $('.upload-attachment-form [name=seller_invoice]').on('change', function(event) {
        const $form = $(this).closest('form');

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: new FormData($form[0]),
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_ATTACHMENT_UPLOADED'], '', function() {
                        window.location.reload();
                    });
                }
                else {
                    showMessageBox('danger', msg['ERR_ATTACHMENT_UPLOAD_FAIL']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.delete-attachment-form').each(function() {
        $(this).validate({
            submitHandler: function (form) {
                const $form = $(form);

                $.ajax({
                    method: $form.attr('method'),
                    url: $form.attr('action'),
                    dataType: 'json',
                    success: function(data) {
                        if (data.success) {
                            showMessageBox('success', msg['MSG_ATTACHMENT_DELETED'], '', function() {
                                window.location.reload();
                            });
                        }
                        else {
                            showMessageBox('danger', msg['ERR_NO_ATTACHMENT_TO_DELETE']);
                        }
                    },
                    error: function() {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                });
            }
        });
    });

    $('.addtocart-form').on('submit', function(event) {
        event.preventDefault();
        const form = $(this);

        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (!data.success) {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
                else {
                    rewixUpdateCart(form);
                    location.href = '/' + $('html').attr('lang') + '/cart';
                }
            },
            error: function() {
                if (exists('#contact-no-quantity')) {
                    $('#contact-no-quantity').foundation('open');
                } else {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            }
        });
    });

    $('#sort-orders')
        .on('change', function() {
            $(this).trigger('submit');
        })
        .on('submit', function(event) {
            event.preventDefault();
            const url = new URL(this.action, window.location.origin);
            const $form = $(this);
            const status = $form.find('[name="status"]').val();
            const sort = $form.find('[name="sort"]').val();
            const dir = $form.find('[name="dir"]').val();
            
            if (status.length > 0) {
                for (const value of status.split(',')) {
                    url.searchParams.append('status', value);
                }
            }

            const search = $form.find('[name="search"]');
            if (search.length > 0) {
                url.searchParams.append('search', search.val());
            }

            url.searchParams.append('sort', sort);
            url.searchParams.append('dir', dir);

            window.location.href = url.toString();
        });

    $('#orders-search').on('submit', function(event) {
        event.preventDefault();
        const url = new URL(this.action, window.location.origin);
        const $form = $(this);
        const search = $form.find('[name="search"]').val();
        const sort = $form.find('[name="sort"]').val();
        const dir = $form.find('[name="dir"]').val();

        const currentUrl = new URL(window.location.href);
        if (currentUrl.searchParams.has('status')) {
            for (const status of currentUrl.searchParams.getAll('status')) {
                url.searchParams.append('status', status);
            }
        }

        if (search.length > 0) {
            url.searchParams.append('search', search);
        }

        url.searchParams.append('sort', sort);
        url.searchParams.append('dir', dir);

        window.location.href = url.toString();
    });
}