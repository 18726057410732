$(document).ready(function() {

    // Pages
    if (exists('#catalog-container')) {
        catalogPage();
    }
    else if (exists('#cart-container')) {
        cartPage();
    }
    else if (exists('#checkout-container')) {
        checkoutPage();
    }
    else if (exists('#profile-content')) {
        profilePage();
        if (exists('.profile-orders, .cancel-return-request-form, #sort-orders, #orders-search')) {
            profileOrders();
        }
    }
    else if (exists('#login-form, #subscribe-form')) {
        loginPage();
        subscribePage();
    }
    else if (exists('#designers-container')) {
        designersPage();
    }

    // Components
    if (exists('.product-container, .product-item')) {
        productComponent();
    }
    if (exists('.main-menu, .mobile-menu')) {
        menuComponent();
    }
    if (exists('#search-submit, #search-archive')) {
        searchComponent();
    }
    if (exists('#subscribe-newsletter')) {
        subscribeNewsletterComponent();
    }
    if (exists('.contact-form')) {
        contactFormComponent();
    }
    if (exists('#subscription-activate-form, #subscriptions-table')) {
        subscriptionFormComponent();
    }
});