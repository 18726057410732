function designersPage() {

  $('.letters-div').addClass("sticky-letters");

  var anchors = document.querySelectorAll('a[href^="#"].card');
  for (i = 0; i < anchors.length; i++) {
      var anchor = anchors[i];
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          $('.card').removeClass('bg-letters-active');
          if (!this.classList.contains('nav-toggle')) {
              this.classList.add("bg-letters-active");
          }

          var element = document.querySelector(this.getAttribute('href'));
          var headerOffset = 65 + 84 + 39;
          if (Foundation.MediaQuery.is('large')) {
              headerOffset = 60 + 84 + 39;
          }
          var elementPosition = element.getBoundingClientRect().top + window.scrollY;
          var offsetPosition = elementPosition - headerOffset;;

          window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
          });  
      });
  }

  $('.designer-search').on('input', function () {
    var input = $(this)
    var elements = $('.designer-list li')
    for (var i = 0; i < elements.length; i++) {
      if (!(elements[i].innerText.includes(input.val().toUpperCase()) || elements[i].innerText.includes(input.val().toLowerCase()) || elements[i].innerText.includes(input.val()[0].toUpperCase() + input.val().substring(1).toLowerCase()))) {
        elements[i].classList.add('hide')
      } else {
        elements[i].classList.remove('hide')
      }
    }
    $('.designer-list').each(function() {
        if ($(this).children().length == $(this).children('.hide').length) {
            $(this).parents('.row').first().addClass('hide')
        } else {
            $(this).parents('.row').first().removeClass('hide')
        }
    })
  });

  $('.designer-search').on('click', function () {
    $('.row.mb-24').removeClass('hide')
    $('.designer-list li').removeClass('hide')
  });

}