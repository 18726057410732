// landing subscribe

$(document).ready(function () {
    if (exists("#landing-videochiamata")) {
        $(".body-container").attr("style", "margin-top:0px!important");
        $(".margin-section").attr("style", "margin-top:0px!important");
    }

    if (exists(".box-banner-figure")) {
        new Rellax(".box-banner-figure");
    }

    if (exists(".new-arrivals")) {
        let swiper = initSwiper(".new-arrivals", {
            speed: 10000,
            autoplay: {
                delay: 1,
                disableOnInteraction: false,
            },
            autoHeight: false,
            spaceBetween: 0,
            slidesPerView: 2,
            mousewheel: {
                forceToAxis: true,
                invert: true,
            },
            loop: true,
            breakpoints: {
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4.5,
                },
            },
        });

        var flagDirection = 1;
        var oldScrollNumber = 0;

        window.onscroll = function () {
            moveSlider();
        };

        function moveSlider() {
            const translateSpeed = 1000;

            if (
                document.body.scrollTop > 50 ||
                document.documentElement.scrollTop > 50
            ) {
                if (document.documentElement.scrollTop > oldScrollNumber) {
                    flagDirection = 1;
                } else {
                    flagDirection = -1;
                }
                oldScrollNumber = document.documentElement.scrollTop;
                swiper.translateTo(
                    swiper.getTranslate() -
                        (document.documentElement.scrollTop * flagDirection) /
                            2,
                    translateSpeed
                );
            }
        }
    }

    // Sync2Fashion
    $("#install-shop").validate({
        rules: {
            shop: {
                required: true,
                regex: /^(?!:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm, // match URL that does NOT start with http(s)://
            },
        },
        messages: {
            shop: {
                required: msg["ERR_MISSINGFIELDS"],
                regex: msg["ERR_SYNC2FASHION_URL"],
            },
        },
        errorPlacement: function (error, element) {
            $(error)
                .css({
                    position: "relative",
                    top: "-16px",
                    width: "max-content",
                })
                .insertAfter(element);
        },
    });
});
